/* eslint-disable array-callback-return, consistent-return, global-require */

import { canUseDOM } from 'exenv';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  get,
  set,
  find,
  toNumber,
  isEmpty,
  isUndefined,
  throttle
} from 'lodash';
import Cookies from 'js-cookie';
import highlightShare from 'highlight-share/dist/highlight-share';
import * as facebookSharer from 'highlight-share/dist/sharers/facebook';
import { imageSizesLookup } from '../../../../utils/cloudinary_asset_util';
import { isAdvertorial } from '../../../../views/components/advertorial/advertorial';
import EventModel from '../../../../utils/tracking/models';
import { UserContext } from '../../../components/context/userContext';
import trackThirdPartyJS from '../../../../client/utils/third_party_trackers/trackThirdPartyJS';
import matchMediaDesktop from '../../../../utils/client_util';
import { findAdvertorialTagID } from '../../../../utils/advertorial_utils';
import { infiniteScrollUrlChange } from '../../../../redux/action_creators/post/postpage_action_creators';
import asyncPostFiniteActions from '../../../../redux/actions/post/async_post_finite_actions';
import asyncPostNativeAdActions from '../../../../redux/actions/post/async_post_native_ad_actions';
import { isCategory } from '../../../../utils/category_util';
import generateVideoCustomParams from '../../../../utils/seo_utils/video/video_post_custom_params_util';
import isRedesignedArticle from '../../../../utils/isRedesignedArticle';
import clsx from 'clsx';
import * as twitter from './post_page_twitter_icon';
import loadable from '@loadable/component';

const PackageStickyNav = loadable(() =>
  import('./packages/nav/package_sticky_nav')
);
const Insert = loadable(() =>
  import('../../../components/post/content/insert')
);
const PostsContainer = loadable(() =>
  import('../../../components/post/container')
);
const MoreStories = loadable(() =>
  import('../../../components/more-stories-component/more-stories-component')
);
const VideoModule = loadable(() =>
  import('../../../components/video_module/video_module')
);
const EditorHighlights = loadable(() =>
  import('../../../components/editor_highlights/editor_highlights')
);
const NotFound = loadable(() =>
  import('../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);
const FiniteScroll = loadable(() =>
  import('../../../components/finite_scroll')
);
const StickyBar = loadable(
  () => import('../../../components/StickyBar/StickyBar'),
  {
    resolveComponent: components => components.StickyBar
  }
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

let scriptjs = false;

if (canUseDOM) {
  scriptjs = require('scriptjs'); // eslint-disable-line global-require
}

// Minimum height for second scroll placeholder container
const SECOND_SCROLL_MIN_HEIGHT = 5000; // in px

class PostPage extends Component {
  constructor(props) {
    super(props);

    const { live } = props.post;
    this.loaded = false;

    const isListOrListArticle =
      get(props, 'post.customFields.is_list', false) ||
      get(props, 'post.customFields.list_article.is_list_article', false);
    const isMobile = get(props, 'config.deviceType', false) === 'smartphone';

    this.state = {
      showSecondScrollPost: false,
      finitePosts: [],
      displayingAdhesiveUnit: isMobile && !isListOrListArticle,
      hasNativeSecond: false,
      nativeInfiniteScrollData: {},
      nativeSecondHasLoaded: false,
      nativeSecondHasBeenTracked: false,
      secondScrollPost: {},
      parselyArticles: {},
      isSecondScroll: false
    };

    // Refs
    this.mobileAdhesiveRef = React.createRef();
    this.scrollSentinelRef = React.createRef();

    // var init
    this.finiteScrollDepth = null;
    this.calledNativePixel = false;
    this.renderedNativeInfiniteScrollPost = false;
    this.canRenderInfiniteScroll = false;
    this.svgWidth = 34.66;
    this.svgHeight = 24.4;
    this.externalList = undefined;
    this.postId = get(props, 'post.id', null);
    this.specialRedesignArticle = isRedesignedArticle(this.postId);
    this.scrollObserver = null;
    this.adhesiveBannerHasShown = false;

    // For editorial packages...
    this.tags = get(props, 'post.tags', []).map(tag => tag.slug);
    this.tags = this.tags.concat(
      get(props, 'post.tagIds', []).map(tag => tag.toString())
    );
    this.mobileAdhesiveUnitTop = false;
    const lookup = ['package-lander', 'package-article'];
    if (this.tags.map(tag => lookup.some(el => el === tag))) {
      const filteredPackageName = this.tags.filter(tag => {
        if (/^_package/.test(tag)) return tag;
      })[0];
      this.packageName = !isUndefined(filteredPackageName)
        ? filteredPackageName.replace('_package-', '')
        : null;
    }
    // Special Tag Navs
    if (this.tags.includes('165107')) {
      // `most-innovative-companies-2024`
      this.packageName = 'mic-2024';
      this.externalList = [{ category: 'Most Innovative Companies' }];
    }
    if (this.tags.includes('162615')) {
      // `most-innovative-companies-2023`
      this.packageName = 'mic-2023';
      this.externalList = [{ category: 'Most Innovative Companies' }];
    }
    if (this.tags.includes('159150')) {
      // `most-innovative-companies-2022`
      this.packageName = 'mic-2022';
    }
    if (this.tags.includes('159884') || this.tags.includes('159853')) {
      // `world-changing-ideas-2022`
      this.packageName = 'world-changing-ideas-awards-2022';
    }
    if (this.tags.includes('161569')) {
      // `most-innovative-companies-2022`
      this.packageName = 'mcp-2022';
    }
    if (this.tags.includes('161738')) {
      this.packageName = 'ambition-diaries';
    }
    if (this.tags.includes('161767')) {
      this.packageName = 'ibd-2022-2';
    }
    if (this.tags.includes('162887')) {
      this.packageName = 'creator25';
    }
    if (this.tags.includes('162979')) {
      this.packageName = 'brands-that-matter-2022';
    }
    if (this.tags.includes('163165')) {
      this.packageName = 'next-big-things-in-tech';
      this.svgWidth = 22;
      this.svgHeight = 16;
      this.externalList = [{ category: 'Winners' }, { category: 'Categories' }];
    }
    if (this.tags.includes('163765')) {
      this.mobileAdhesiveUnitTop = true;
    }

    if (this.packageName) {
      // Try to keep this list in alphabetical order for our sanity as it grows.
      switch (this.packageName) {
        case 'black-in-tech':
          this.packageComponent = require('./packages/black-in-tech').default;
          this.packageNav = false;
          break;

        case 'the-new-worker-moment':
          if (this.tags.includes('package-lander')) {
            this.packageComponent = require('./packages/fc-labor/package_lander.js').default;
            this.packageNav = true;
            break;
          } else if (this.tags.includes('package-article')) {
            this.packageComponent = require('./packages/fc-labor/package_article.js').default;
            this.packageNav = true;
            break;
          } else if (this.tags.includes('package-article-list')) {
            this.packageComponent = require('./packages/fc-labor/package_article_list.js').default;
            this.packageNav = true;
            break;
          } else {
            this.packageComponent = null;
            this.packageNav = false;
          }
          break;

        default:
          this.packageComponent = null;
          this.packageNav = false;
          break;
      }
    }

    this.playerScriptIds = [
      'QV73eWiB',
      '4h5SWQPt',
      'Pafg29um',
      '27mBLzWL',
      'i70nsZ8K',
      'Lrp0YsKC',
      'wGNMZweZ',
      'kMmGs8qw',
      'WXJihrBk',
      'iqio3VCM',
      'OolwHeKf',
      'FsIzKJce',
      'r04h3Jdn',
      'RvGXj7in',
      'flarUGPa',
      'HzdTLaau',
      'gAy6i1bi',
      'RqEFED7X',
      'vp2iCnvh',
      'b2BPV4nc'
    ];

    this.live = get(live, 'enabled', false) && !!get(live, 'posts.length', 0);
    this.noscroll = false;
    this.firstIsSet = false;
    this.loadPostFinite = this.loadPostFinite.bind(this);
    this.handleScrollObserver = this.handleScrollObserver.bind(this);
    this.handleIntersection = this.handleIntersection.bind(this);
    this.setInviewPostUrl = this.setInviewPostUrl.bind(this);

    this.setScrollEvents = throttle(() => {
      const { scrollY, innerHeight } = window;
      this.setInviewPostUrl(scrollY, innerHeight);
    }, 1000);
  }

  componentDidMount() {
    const { statusCode, post } = this.props;
    const postCategories = get(post, 'categories', false);

    this.window = window;
    this.windowWidth = this.window.innerWidth;
    this.body = document.body;
    this.activePost = get(post, 'id') || get(this.props, 'match.params.id');
    this.lazyThresholdFromBottom = 400;
    this.stickySidebar = matchMediaDesktop();
    this.trackedPosts = [];

    // Scroll observer for second article display logic
    this.handleScrollObserver();

    if (this.packageName) {
      document.body.classList.add(this.packageName);
    }

    if (get(this.props.config, 'featureFlags.textToShare')) {
      this.selectionShare = highlightShare({
        selector: '.post__body',
        sharers: [facebookSharer, twitter],
        onOpen: element => {
          element.setAttribute('id', `user_highlight--${this.activePost}`);

          const highlightShareEvent = new EventModel(
            'Highlight Share',
            'articleFormat',
            'hovered',
            'exposed',
            this.getPercentIntoPost(element)
          );

          highlightShareEvent.triggerTrackingEvent(
            'default-click',
            highlightShareEvent.getTrackingObject()
          );
        }
      });
      this.selectionShare.init();
    }

    this.setActiveSocial();
    this.refreshSocialEmbeds();

    if (!this.live) {
      this.window.addEventListener('scroll', this.setScrollEvents);
    }

    if (
      statusCode !== 404 &&
      statusCode !== 403 &&
      statusCode !== 500 &&
      postCategories
    ) {
      this.loadPostFinite();
    }

    this.loaded = true;
  }

  componentWillReceiveProps(nextProps) {
    if (!window.fc_ads) {
      window.fc_ads = {
        keyVals: {},
        native: {}
      };
    }
    if (!window.fc_ads.getNative) {
      window.fc_ads.getNative = nativeType => {
        this.getNative(nativeType);
      };
    }
    // if (isAdvertorial(get(nextProps, 'post.tags'))) {
    //   return;
    // }

    if (get(nextProps, 'postNativeAd.nativeSecond.posts', []).length) {
      const abGroupCookie = Cookies.get('abgroup');
      let defaultABGroup = 0; // eslint-disable-line
      if (abGroupCookie) {
        defaultABGroup = parseInt(abGroupCookie, 10);
      }

      if (
        typeof this.state.finitePosts.find(
          post =>
            post.id === get(nextProps, 'postNativeAd.nativeSecond.posts[0].id')
        ) === 'undefined'
      ) {
        this.setState({
          secondScrollPost: get(
            nextProps,
            'postNativeAd.nativeSecond.posts[0]'
          ),
          hasNativeSecond: true,
          finitePosts: [
            ...this.state.finitePosts,
            get(nextProps, 'postNativeAd.nativeSecond.posts[0]')
          ]
        });
      }
    }
  }

  shouldComponentUpdate() {
    if (this.packageName) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    const live = get(this.props, 'post.live');
    this.live = get(live, 'enabled', false) && !!get(live, 'posts.length', 0);
    this.refreshSocialEmbeds();
    this.posts = this.getPostElements();
    const prevPropsCategories = get(prevProps, 'post.categories', false);
    const currPropsCategeroies = get(this.props, 'post.categories', false);

    if (
      prevPropsCategories !== currPropsCategeroies &&
      prevPropsCategories === false
    ) {
      this.loadPostFinite();
    }

    if (isEmpty(prevProps.post) && !isEmpty(this.props.post)) {
      this.firstIsSet = true;
      this.context.retriggerPianoPost(
        this.props.post,
        get(this.props.post, 'id', 'NI'),
        this.props.supertags
      );
    } else {
      const thisPost = get(this.props, 'post');
      if (!this.firstIsSet && !isEmpty(thisPost)) {
        this.firstIsSet = true;
        this.context.retriggerPianoPost(
          this.props.post,
          get(this.props.post, 'id', 'NI'),
          this.props.supertags
        );
      }
    }
  }

  componentWillUnmount() {
    if (!this.live) {
      this.window.removeEventListener('scroll', this.setScrollEvents);
    }
    if (get(this.props.config, 'featureFlags.textToShare')) {
      this.selectionShare.destroy();
    }
    this.scrollObserver.disconnect();
  }

  onAdhesiveBannerDisplayed(value) {
    const oldValue = this.adhesiveBannerHasShown;
    this.adhesiveBannerHasShown = value;

    if (!oldValue && value) {
      const adhesiveBannerShownEvent = new EventModel();
      adhesiveBannerShownEvent.pushKeyValueToSpeedCurve(
        'adhesiveBannerShown',
        1
      );
    }
  }

  setInviewPostUrl(windowPosition, screenHeight) {
    if (!this.posts || !get(this.posts, 'length')) {
      return false;
    }

    const triggerPoint = windowPosition + screenHeight;

    // Iterate each post to determine if it is in view
    for (let i = 1; i <= get(this.posts, 'length'); i += 1) {
      const post = this.posts[i - 1];
      const sectionTopPosition = post.offsetTop;
      const sectionBottomPosition = sectionTopPosition + post.clientHeight;
      const postId = parseFloat(post.dataset.id);
      const postIsInView =
        sectionTopPosition <= triggerPoint &&
        sectionBottomPosition > triggerPoint;

      // If a post is in view and it is not the active post, update URL, title, etc.
      if (this.activePost !== postId && postIsInView) {
        const { supertags } = this.props;
        let postData =
          i === 1 ? this.props.post : this.state.finitePosts[i - 2];

        if (!isEmpty(this.state.secondScrollPost) && this.activePost === 0) {
          postData = this.state.secondScrollPost;
        }
        this.activePost = postData.id;

        // React router redux does not support replace state without page refresh.
        this.props.dispatchInfiniteScrollUrlChange();
        let replacedURL;
        if (postData.isNewUrl) {
          replacedURL = `/${postData.newUrl
            .split('/')
            .slice(-2)
            .join('/')}`;
        } else {
          this.setState({ isSecondScroll: !this.state.isSecondScroll });
          const { search } = window.location || '';
          replacedURL = `/${postData.id}/${postData.slug}${search}`;
        }

        if (get(postData, 'isVideoPermalink')) {
          replacedURL = `/video/${postData.slug}/${get(
            postData,
            'posterVideo.feedId'
          )}`;
        }

        window.history.replaceState({}, '', replacedURL);
        document.title = get(postData, 'title');

        this.trackedPosts.push(this.activePost);

        // Fire trackers only ONCE for second scroll article
        if (
          this.state.nativeSecondHasLoaded &&
          !this.state.nativeSecondHasBeenTracked
        ) {
          this.refreshThirdPartyTrackers(postData, i, supertags);
          this.setState({ nativeSecondHasBeenTracked: true });
        }

        // "refresh" blueconic so listeners will be executed again
        if (typeof window.blueConicClient.handlePageView === 'function') {
          window.blueConicClient.handlePageView();
        }

        this.setActiveSocial(post);
      }
    }
    return false;
  }

  setActive() {
    this.forceUpdate();
  }

  getNative(nativeType) {
    const nativeAd = get(window, `fc_ads.native.${nativeType}`);
    if (nativeAd) {
      return this.props.dispatchPostNativeAd(
        nativeAd,
        get(this.props, 'config')
      );
    }

    return false;
  }

  setActiveSocial(post) {
    const activeClass = 'social--active';
    const activeSocial = document.querySelector(`.${activeClass}`);

    if (activeSocial) {
      activeSocial.classList.remove(activeClass);
    }

    const activePost = post || document.querySelector('.post__wrapper');
    if (activePost && activePost.querySelector('.social')) {
      activePost.querySelector('.social').classList.add(activeClass);
    }
  }

  getPercentIntoPost(element) {
    const post = document.querySelector(`#post__wrapper-${this.activePost}`);
    const { top = 0, height = 0 } = post.getBoundingClientRect() || {};
    const postHeight = height - top;
    const highlightPosition = parseInt(element.style.top || 0, 10) - top;
    const percent = highlightPosition / postHeight * 100;
    return percent;
  }

  getPostElements() {
    return document.querySelectorAll('.post__body');
  }

  // Get height of mobile ad adhesive units - used to determine placement of adhesive video on mobile
  getAdhesiveHeight() {
    if (this.state.displayingAdhesiveUnit) {
      if (this.mobileAdhesiveRef && this.mobileAdhesiveRef.current) {
        return this.mobileAdhesiveRef.current.clientHeight;
      }
    }

    return 0;
  }

  handleScrollObserver() {
    if (this.scrollSentinelRef.current) {
      this.scrollObserver = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px 0px 600px 0px', // Set negative rootMargin to offset trigger by 600px before the element enters the viewport from the bottom
        threshold: 1.0
      });
      this.scrollObserver.observe(this.scrollSentinelRef.current);
    }
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && !this.state.showSecondScrollPost) {
        this.setState({
          showSecondScrollPost: true
        });
      }
    });
  }

  loadPostFinite() {
    const postId = get(this.props, 'match.params.id');
    const config = get(this.props, 'config', {});
    const post = get(this.props, 'post', {});
    const postCategories = get(post, 'categories', []);
    const isMCPList = get(post, 'customFields.show_mcp_list', false);
    const isBWIList =
      get(post, 'customFields.select_the_list', false) === 'bwi2020';
    const isMICList =
      get(post, 'customFields.select_the_list', false) === 'mic2021';
    const isIBDList = find(get(post, 'tags'), {
      name: 'innovation by design 2020'
    });

    const advertorialTag = findAdvertorialTagID(get(post, 'tags'));
    const isRecommender = isCategory(postCategories, 'recommender');

    let override = null;

    if (isRecommender) {
      override = 'recommender';
    } else if (isMCPList) {
      override = 'mcp';
    } else if (isBWIList) {
      override = 'bwi';
    } else if (isIBDList) {
      override = 'ibd';
    } else if (find(get(post, 'tags'), { slug: 'innovation-festival-360' })) {
      override = 'innovation-festival-360';
    } else if (isMICList) {
      override = 'mic';
    } else {
      override = null;
    }

    this.props.dispatchPostFinite(
      postId,
      advertorialTag,
      config,
      this.props.mic2020Companies,
      this.props.mic2020Regions,
      this.props.mic2020Sectors,
      override
    );
  }

  refreshSocialEmbeds() {
    if (canUseDOM) {
      if (!window.instgrm) {
        scriptjs('//platform.instagram.com/en_US/embeds.js', () => {
          window.instgrm.Embeds.process();
        });
      } else {
        window.instgrm.Embeds.process();
      }

      scriptjs('//platform.twitter.com/widgets.js', () => {
        window.twttr.widgets.load();
      });
      // window.twttr.widgets.load();
      scriptjs('//view.ceros.com/scroll-proxy.min.js');
      scriptjs(
        '//creative-services.ceros.com/customer-success/varying-embed-tag-generator/embedded-variant-switcher-v3.min.js'
      );
    }
  }

  refreshThirdPartyTrackers(postData, pageNumber, supertags) {
    const { config } = this.props;
    const currentActivePostState = {
      context: {
        site: get(config, 'site'),
        type: 'post',
        postData,
        supertags
      },
      config
    };
    // This is apparently where all the page data gets reset:
    trackThirdPartyJS(currentActivePostState, pageNumber);
  }

  determineAdhesiveClass() {
    if (this.mobileAdhesiveUnitTop) {
      return 'mobile-adhesive-top';
    }
    return '';
  }

  renderVideo() {
    const { postVideo, config, post } = this.props;
    const feedID = get(post, 'posterVideo.feedId');
    const customparams = generateVideoCustomParams(post);

    // Do not display video module when on a video post
    if (feedID) {
      return '';
    }

    if (!isEmpty(postVideo)) {
      const currentSite = get(this.props, 'config.site');
      get(config, 'site');
      return (
        <VideoModule
          config={config}
          data={postVideo}
          playerid="MyQ2uJQ0"
          pos="newmultiplayer"
          stories={this.props.postVideo.playlist}
          currentSite={currentSite}
          isPost={true}
          readTime={get(post, 'readTime')}
          customparams={customparams}
        />
      );
    }

    return null;
  }

  renderMoreStories() {
    let postMoreStories = get(this.props, 'postMoreStories.posts');

    const { postAfterbodyRecircModuleType, config } = this.props;
    const postAfterbodyRecircModuleTiles = get(
      postAfterbodyRecircModuleType,
      'tiles'
    );
    const postAfterbodyRecircModuleTilesAdzoneMetadata = get(
      postAfterbodyRecircModuleType,
      'tileAdzone',
      ''
    );

    const postAfterbodyRecircModuleTilesAdzoneMetadataSplit = postAfterbodyRecircModuleTilesAdzoneMetadata.split(
      ','
    );
    let slugData;

    if (postAfterbodyRecircModuleTiles) {
      const currentDate = new Date();
      const isEvenDay = toNumber(currentDate.getDate()) % 2 === 0;

      postMoreStories = postAfterbodyRecircModuleTiles.map((story, idx) =>
        Object.assign(story, {
          id: `12345-${idx}`,
          currentSite: get(config, 'site'),
          events: false,
          assetSizes: imageSizesLookup.bigFirstMobile,
          noLazy: true,
          isSponsoredTile: isEvenDay,
          external: true
        })
      );

      slugData = {
        name: get(postAfterbodyRecircModuleTilesAdzoneMetadataSplit, '[1]'),
        tagLine: get(postAfterbodyRecircModuleTilesAdzoneMetadataSplit, '[2]')
      };
    }

    if (
      postMoreStories &&
      get(postMoreStories, 'length') &&
      get(this.props, 'postFinite.length') &&
      canUseDOM
    ) {
      return (
        <Fragment>
          {postAfterbodyRecircModuleTilesAdzoneMetadata ? (
            <Fragment>
              <MoreStories
                metadata={postMoreStories}
                isPost={true}
                slugData={slugData}
                config={this.props.config}
                hasSponsoredContentLabel={true}
                noBottomBorder={true}
              />
            </Fragment>
          ) : (
            <MoreStories
              metadata={postMoreStories}
              isPost={true}
              config={this.props.config}
            />
          )}
        </Fragment>
      );
    }

    return null;
  }

  renderPostFinite(postFinite, related, sticky, config, playerIds, event) {
    if (!get(postFinite, 'length')) {
      return null;
    }

    return postFinite.map((postData, index) => {
      if (!postData) return null;

      if (postData.isVideoPermalink || get(postData, 'posterVideo.feedId')) {
        if (!playerIds[index + 1]) {
          set(postData, 'playerScriptId', 'G2hQKLvX');
        } else {
          set(postData, 'playerScriptId', playerIds[index + 1]);
        }
      }

      const expandVal = postData.cardType
        ? this.state.nativeInfiniteScrollData.expanded
        : undefined;

      return (
        <Fragment>
          <Insert
            key={`insert_${index}`}
            expand={expandVal}
            config={config}
            tags={get(postData, 'tags')}
            postData={postData}
            insert={true}
            pageNum={index + 1}
            related={related}
            live={false}
            isDesktop={sticky}
            mic2020Companies={this.props.mic2020Companies}
            mic2020Regions={this.props.mic2020Regions}
            mic2020Sectors={this.props.mic2020Sectors}
            postPromoterArticleText={this.props.postPromoterArticleText}
            event={event}
          />
        </Fragment>
      );
    });
  }

  render() {
    const {
      config,
      statusCode,
      post,
      related,
      postpage,
      postPromoterArticleText,
      supertags
    } = this.props;

    const abGroupCookie = Cookies.get('abgroup');
    let defaultABGroup = 0;
    if (abGroupCookie) {
      defaultABGroup = parseInt(abGroupCookie, 10);
    }

    /**
     * This post should be rendered as a "special package" view
     */
    if (this.packageComponent) {
      const PackageComponent = this.packageComponent;
      return (
        <Fragment>
          <PackageComponent post={post} packageName={this.packageName} />
          {this.packageNav && <PackageStickyNav {...this.props} />}
        </Fragment>
      );
    }

    let shouldRenderVideoModule = true;
    if (
      (defaultABGroup >= 1 && defaultABGroup <= 2) ||
      isAdvertorial(get(post, 'tags'))
    ) {
      shouldRenderVideoModule = false;
    }
    if (defaultABGroup >= 3 && defaultABGroup <= 20) {
      shouldRenderVideoModule = false;
    }

    /**
     * Page data still loading, display loading component
     */
    if (postpage.isLoading && statusCode === 200) {
      return (
        <section className="post-page">
          <article className="post__main">
            <LoadingSpinner />
          </article>
        </section>
      );
    }

    /**
     * Error loading the page or page data, display error component
     */
    if (postpage.error || isEmpty(post)) {
      return (
        <section className="post-page">
          <article className="post__main--error-pg">
            <NotFound
              statusCode={statusCode}
              message={
                [401, 403].includes(statusCode)
                  ? 'Post is not available.'
                  : undefined
              }
            />
          </article>
        </section>
      );
    }

    const sticky = this.stickySidebar;

    if (post.isVideoPermalink || get(post, 'posterVideo.feedId')) {
      set(post, 'playerScriptId', this.playerScriptIds[0]);
    }

    const customTagPlayerId = get(post, 'tags', []).filter(item =>
      get(item, 'slug', '').includes('jwplayer_')
    );

    if (customTagPlayerId.length > 0) {
      set(
        post,
        'playerScriptId',
        customTagPlayerId[0].slug.replace('jwplayer_', '')
      );
    }

    // Ignore second scroll for these tags
    const tagsWithoutSecondScroll = ['_custom', 'advertorial'];
    const showInfiniteExperience = !tagsWithoutSecondScroll.some(tag =>
      this.tags.includes(tag)
    );

    const events = ['innovation-festival-360'];
    let event = null;

    post.tags.forEach(tag => {
      if (events.includes(tag.slug)) {
        event = tag.slug;
      }
    });

    const containerProps = {
      config: this.props.config,
      posts: [post],
      pageNum: 0,
      related,
      isDesktop: sticky,
      isFirstPost: true,
      live: this.live,
      mic2020Companies: this.props.mic2020Companies,
      mic2020Regions: this.props.mic2020Regions,
      mic2020Sectors: this.props.mic2020Sectors,
      postPromoterArticleText,
      tags: post.tags,
      event: event || null,
      canRenderInfiniteScroll: this.canRenderInfiniteScroll,
      recircArticles: this.state.parselyArticles.recirc,
      supertags
    };

    const showInfiniteScroll =
      this.loaded &&
      this.state.hasNativeSecond &&
      this.state.showSecondScrollPost;

    if (showInfiniteScroll && !this.state.nativeSecondHasLoaded) {
      const highlightShareEvent = new EventModel();
      highlightShareEvent.pushKeyValueToSpeedCurve('secondScrollLoaded', 1);
    }

    const shouldShowAdhesiveBanner =
      this.state.displayingAdhesiveUnit && !this.packageName;

    if (shouldShowAdhesiveBanner && canUseDOM) {
      this.onAdhesiveBannerDisplayed(true);
    }

    return (
      <React.Fragment>
        <section
          className={clsx(
            'post-page',
            { 'adhesive-ad-active': this.state.displayingAdhesiveUnit },
            { redesign: this.specialRedesignArticle }
          )}
        >
          {/* Editor Highlights marks, floating left unit */}
          {this.activePost && (
            <EditorHighlights activePostId={this.activePost} />
          )}

          {/* Bottom Adhesive ad unit [mobile only!] */}
          {shouldShowAdhesiveBanner && (
            <div
              className={`adhesive adhesive-banner ${this.determineAdhesiveClass()}`}
              ref={this.mobileAdhesiveRef}
            >
              <AdContainer type="adhesive" hide={this.state.isSecondScroll} />
              {this.state.hasNativeSecond && (
                <AdContainer
                  type="adhesive"
                  hide={!this.state.isSecondScroll}
                  page="2"
                />
              )}
            </div>
          )}
          <section
            className={`post-page ${event ? `event-video ${event}` : ''} `}
          >
            <PostsContainer
              getAdhesiveHeight={this.getAdhesiveHeight.bind(this)}
              {...containerProps}
            />
          </section>

          {this.live || this.noscroll ? (
            <div className="post__article-top-ad-wrapper">
              {!this.state.hasNativeSecond && (
                <AdContainer
                  type="break"
                  page={this.state.hasNativeSecond ? 2 : 1}
                />
              )}
            </div>
          ) : (
            <div>{shouldRenderVideoModule ? this.renderVideo() : ''}</div>
          )}
        </section>
        <div ref={this.scrollSentinelRef} className="scrollSentinel" />
        {this.packageName && (
          <StickyBar
            slug={this.packageName}
            lists={null}
            location="article"
            post={post}
            svgWidth={this.svgWidth}
            svgHeight={this.svgHeight}
            externalData={this.externalList}
          />
        )}

        {/* Infinite Scroll Experience */}
        {showInfiniteExperience && (
          <section
            className="nativeSecondScroll__container"
            style={{
              minHeight: `${SECOND_SCROLL_MIN_HEIGHT}px`
            }}
          >
            {showInfiniteScroll && (
              <FiniteScroll
                post={post}
                secondScrollPost={this.state.secondScrollPost}
                setSecondScrollWasMounted={() =>
                  this.setState({ nativeSecondHasLoaded: true })
                }
                articles={this.state.parselyArticles.articles}
                config={config}
                abGroup={defaultABGroup}
                options={{
                  sticky,
                  event: event || '',
                  related,
                  mic2020Companies: get(this.props, 'mic2020Companies', []),
                  mic2020Regions: get(this.props, 'mic2020Regions', []),
                  mic2020Sectors: get(this.props, 'mic2020Sectors', []),
                  postPromoterArticleText: get(
                    this.props,
                    'postPromoterArticleText',
                    ''
                  ),
                  postNativeAd: get(this.props, 'postNativeAd', {})
                }}
                supertags={supertags}
              />
            )}
          </section>
        )}
      </React.Fragment>
    );
  }
}

PostPage.contextType = UserContext;

function mapStateToProps(state = {}) {
  return {
    statusCode: isEmpty(get(state, 'post.data.post', {}))
      ? 404
      : get(state, 'status.code'),
    config: state.config,
    postpage: state.postpage,
    post: get(state, 'post.data.post', {}),
    related: get(state, 'post.data.related', []),
    postVideo: get(state, 'postVideo.data', {
      feedid: '',
      playlist: [],
      title: ''
    }),
    postAfterbodyRecircModuleType: get(
      state,
      'postAfterbodyRecircModuleType.data'
    ),
    postPromoterArticleText: get(state, 'postPromoterArticleText.data.text'),
    postMoreStories: get(state, 'postMoreStories.data'),
    postFinite: get(state, 'postFinite.data.posts', []),
    postNativeAd: get(state, 'postNativeAd.data', {}),
    nativeInfiniteScrollPost: get(
      state,
      'postNativeAd.data.infiniteScroll.posts',
      []
    ),
    mic2020Companies: get(state, 'mic2020Companies.companies', []),
    mic2020Regions: get(state, 'mic2020Regions.regions', []),
    mic2020Sectors: get(state, 'mic2020Sectors.sectors', []),
    supertags: get(state, 'supertags.supertags', [])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchInfiniteScrollUrlChange() {
      dispatch(infiniteScrollUrlChange());
    },

    dispatchPostFinite(
      id,
      advertorialTag,
      config,
      companies,
      regions,
      sectors,
      override
    ) {
      return asyncPostFiniteActions(
        dispatch,
        id,
        advertorialTag,
        config,
        companies,
        regions,
        sectors,
        override
      );
    },

    dispatchPostNativeAd(nativeAdsData, config) {
      return asyncPostNativeAdActions(dispatch, config, nativeAdsData);
    }
  };
}

PostPage.defaultProps = {
  postAfterbodyRecircModuleType: {
    type: 'More Stories'
  },
  postPromoterArticleText: '',
  mic2020Companies: [],
  mic2020Regions: [],
  mic2020Sectors: [],
  supertags: []
};

PostPage.propTypes = {
  statusCode: PropTypes.number.isRequired,
  config: PropTypes.shape({}).isRequired,
  dispatchInfiniteScrollUrlChange: PropTypes.func.isRequired,
  dispatchPostNativeAd: PropTypes.func.isRequired,
  dispatchPostFinite: PropTypes.func.isRequired,
  post: PropTypes.shape({
    id: PropTypes.number,
    customFields: PropTypes.shape({
      is_list: PropTypes.bool
    }),
    tags: PropTypes.arrayOf(PropTypes.shape([]))
  }).isRequired,
  related: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postpage: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    postScrollDepth: PropTypes.number
  }).isRequired,
  postVideo: PropTypes.shape({
    feedid: PropTypes.string,
    playlist: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string
  }).isRequired,
  postFinite: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  postNativeAd: PropTypes.shape({}).isRequired,
  postAfterbodyRecircModuleType: PropTypes.shape({
    type: PropTypes.string
  }),
  postPromoterArticleText: PropTypes.string,
  mic2020Companies: PropTypes.arrayOf(PropTypes.shape()),
  mic2020Regions: PropTypes.arrayOf(PropTypes.shape()),
  mic2020Sectors: PropTypes.arrayOf(PropTypes.shape()),
  supertags: PropTypes.arrayOf(PropTypes.string)
};

export default connect(mapStateToProps, mapDispatchToProps)(props => (
  <PostPage {...props} />
));
