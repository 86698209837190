/* eslint-disable no-return-assign, no-unused-vars, no-undef, array-callback-return, no-param-reassign */

import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

import loadable from '@loadable/component';

const AdContainer = loadable(() =>
  import('../../../../../components/ad/AdContainer')
);

export default function PackageArticle(props) {
  const { post, packageName, scrollIndex } = props;

  const postElements = {
    featured_image: get(post, 'featured_image.source', null),
    author: !isEmpty(
      get(post, 'customFields.package_article.attribution.custom_byline', null)
    )
      ? get(
          post,
          'customFields.package_article.attribution.custom_byline',
          null
        )
      : get(post, 'author.name', null),
    photo_credit: get(
      post,
      'customFields.package_article.attribution.photography_credit',
      null
    ),
    headline_image: get(
      post,
      'customFields.package_article.styling.image_before_headline',
      null
    ),
    ceros_header_override: get(
      post,
      'customFields.package_article.styling.ceros_header_override',
      null
    ),
    title: get(post, 'title', null),
    excerpt: get(post, 'excerpt', null),
    drop_cap: {
      type: get(post, 'customFields.package_article.styling.drop_cap', null),
      image: get(
        post,
        'customFields.package_article.styling.drop_cap_image',
        null
      )
    },
    ceros_embed:
      post.customFields.ceros_embed === false
        ? null
        : post.customFields.ceros_embed
  };

  // States
  const [body, setBody] = useState(null);

  // Effects
  useEffect(() => {
    document.body.classList.add('package-article'); // Really don't want to do this but gotta hack it in somehow to adjust top padding

    let copy = '';

    post.content.map(group =>
      group.map(content => {
        const chunk = content.replace(/^<p>|<\/p>$/g, '');

        if (chunk.includes('<figure')) {
          copy += chunk;
        } else {
          copy += `<p>${chunk}</p>`;
        }
      })
    );

    setBody(copy);
  }, []);

  useEffect(
    () => {
      let timeoutId = null;

      const fullWidthImages = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          const images = document.querySelectorAll(
            '.content figure, .content .ceros-embed'
          );
          let fullHeight = null;

          images.forEach(image => {
            if (image.outerHTML.includes('ceros-embed')) {
              fullHeight = image.querySelector('iframe').offsetHeight;
            } else if (image.outerHTML.includes('<figure')) {
              fullHeight =
                image.querySelector('img').height +
                image.querySelector('figcaption').offsetHeight;
            }

            image.style.height = `${fullHeight}px`;
          });
        }, 150);
      };

      window.addEventListener('resize', fullWidthImages);
      fullWidthImages(); // Run the first time a change to "body" happens.

      return () => {
        window.removeEventListener('resize', fullWidthImages);
      };
    },
    [body]
  );

  return (
    <main
      className={`package-article ${packageName}`}
      data-url={post.link}
      data-title={post.title}
    >
      <div className="ad-container">
        <AdContainer type="break" />
      </div>

      {!isEmpty(postElements.ceros_header_override) && (
        <div
          dangerouslySetInnerHTML={{
            __html: postElements.ceros_header_override
          }}
        />
      )}

      {isEmpty(postElements.ceros_header_override) &&
        !isEmpty(postElements.featured_image) && (
          <div
            className="featured-image"
            style={{ backgroundImage: `url(${postElements.featured_image})` }}
          />
        )}

      <div className="wrapper">
        <div className="header">
          {!isEmpty(postElements.headline_image) && (
            <img
              className="headline-image"
              alt=""
              src={postElements.headline_image}
            />
          )}
          {!isEmpty(postElements.title) && <h2>{postElements.title}</h2>}
          {!isEmpty(postElements.excerpt) && <h3>{postElements.excerpt}</h3>}
          {!isEmpty(postElements.author) && (
            <h4>
              By {postElements.author}
              {postElements.photo_credit &&
                `/Photographs by ${postElements.photo_credit}`}
            </h4>
          )}
        </div>

        <div className="inner">
          <div className="sidebar" />

          <div className="content">
            {postElements.drop_cap.type &&
              !postElements.drop_cap.type !== 'none' && (
                <div
                  className={`drop-cap ${
                    postElements.drop_cap.type === 'graphical' ? 'squares' : ''
                  } ${postElements.drop_cap.type === 'image' ? 'image' : ''}`}
                >
                  {postElements.drop_cap.type === 'graphical' && (
                    <Fragment>
                      <div className="square" />
                      <div className="square" />
                      <div className="square" />
                    </Fragment>
                  )}

                  {postElements.drop_cap.type === 'image' && (
                    <img alt="" src={postElements.drop_cap.image} />
                  )}
                </div>
              )}

            {!isEmpty(body) && (
              <div dangerouslySetInnerHTML={{ __html: body }} />
            )}
          </div>
        </div>
      </div>

      {!isEmpty(postElements.ceros_embed) && (
        <div
          className="full-width-embed"
          dangerouslySetInnerHTML={{ __html: postElements.ceros_embed }}
        />
      )}

      <div className="wrapper">
        <div className="inner no-grid">
          <div className="bottom-of-section" />
        </div>
      </div>
    </main>
  );
}

PackageArticle.propTypes = {
  post: PropTypes.shape({}).isRequired,
  packageName: PropTypes.string.isRequired,
  scrollIndex: PropTypes.number
};

PackageArticle.defaultProps = {
  scrollIndex: null
};
