/* eslint-disable no-return-assign, no-unused-vars, no-undef, no-shadow, no-restricted-globals */

import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import axios from 'axios';

import loadable from '@loadable/component';

const PackageArticle = loadable(() => import('./package_article'));
const PackageArticleList = loadable(() => import('./package_article_list'));
const Loading = loadable(() =>
  import('../../../../../components/loading_spinner/loading_spinner')
);
const AdContainer = loadable(() =>
  import('../../../../../components/ad/AdContainer')
);

export default function PackageLander(props) {
  const { post, packageName } = props;

  const postElements = {
    images: {
      desktop:
        post.customFields.package_landers.header_graphic_desktop === false
          ? null
          : post.customFields.package_landers.header_graphic_desktop,
      mobile:
        post.customFields.package_landers.header_graphic_mobile === false
          ? null
          : post.customFields.package_landers.header_graphic_mobile
    },
    title: get(post, 'title', null),
    excerpt: get(post, 'excerpt', null),
    ceros_embed:
      post.customFields.ceros_embed === false
        ? null
        : post.customFields.ceros_embed
  };

  // Refs
  const infiniteScrollTrigger = useRef(null);
  const observer = useRef(null);
  const articleObserver = useRef(null);
  const infiniteScrollIndex = useRef(0);

  // States
  const [body, setBody] = useState(null);
  const [infiniteScrollContent, setInfiniteScrollContent] = useState([]);
  const [articleIndex, setArticleIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  // Functions
  function callback([entry]) {
    if (
      entry.isIntersecting &&
      infiniteScrollIndex.current <
        post.customFields.package_landers.infinite_scroll_articles.length
    ) {
      observer.current.unobserve(infiniteScrollTrigger.current);
      setLoading(true);

      axios(
        `//fc-api.fastcompany.com/api/v2/preview-post-related/fastcompany/${
          post.customFields.package_landers.infinite_scroll_articles[
            infiniteScrollIndex.current
          ].article_id
        }`
      ).then(res => {
        const { tags } = res.data.post;
        const articleTags = tags.map(tag => tag.slug);

        if (articleTags.includes('package-article')) {
          setInfiniteScrollContent(infiniteScrollContent => [
            ...infiniteScrollContent,
            <PackageArticle
              post={res.data.post}
              packageName={packageName}
              scrollIndex={infiniteScrollIndex.current}
            />
          ]);
        } else if (articleTags.includes('package-article-list')) {
          setInfiniteScrollContent(infiniteScrollContent => [
            ...infiniteScrollContent,
            <PackageArticleList
              post={res.data.post}
              packageName={packageName}
              scrollIndex={infiniteScrollIndex.current}
            />
          ]);
        }

        if (
          infiniteScrollIndex.current <
          post.customFields.package_landers.infinite_scroll_articles.length
        ) {
          setArticleIndex(articleIndex => articleIndex + 1);
        }

        setLoading(false);
      });
    }
  }

  // Effects
  useEffect(() => {
    observer.current = new IntersectionObserver(callback, { threshold: 1.0 });

    document.body.classList.add('package-lander'); // Really don't want to do this but gotta hack it in somehow to adjust top padding

    let bodyContent = '';
    post.content.map(group => group.map(content => (bodyContent += content)));
    setBody(bodyContent);
  }, []);

  useEffect(
    () => {
      if (observer.current) {
        const articles = document.querySelectorAll('main.package-article');

        observer.current.observe(infiniteScrollTrigger.current);

        if (articles.length > 0) {
          articleObserver.current = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  document.title = entry.target.dataset.title;
                  const url = entry.target.dataset.url.replace(
                    /^https?:\/\/(\w|\d|\.)*/,
                    ''
                  );
                  history.pushState(null, entry.target.dataset.title, url);
                } else {
                  document.title = post.title;
                  const url = post.link.replace(/^https?:\/\/(\w|\d|\.)*/, '');
                  history.pushState(null, post.title, url);
                }
              });
            },
            { threshold: 1.0 }
          );

          articles.forEach(article => {
            articleObserver.current.observe(article);
          });
        }
      }
    },
    [infiniteScrollContent]
  );

  useEffect(
    () => {
      infiniteScrollIndex.current = articleIndex;
    },
    [articleIndex]
  );

  return (
    <Fragment>
      <main className={`package-lander ${packageName}`}>
        <div className="ad-container">
          <AdContainer type="break" />
        </div>

        <div className="animation">
          {!isEmpty(postElements.images.desktop) && (
            <picture>
              <source
                media="(min-width: 768px)"
                srcset={postElements.images.desktop}
              />
              {!isEmpty(postElements.images.mobile) && (
                <source
                  media="(max-width: 767px)"
                  srcSet={postElements.images.desktop}
                />
              )}
              <img src={postElements.images.desktop} alt="Header" />
            </picture>
          )}
        </div>

        <div className="wrapper">
          <div className="header">
            {!isEmpty(postElements.title) && <h1>{postElements.title}</h1>}
            {!isEmpty(postElements.excerpt) && <h2>{postElements.excerpt}</h2>}
          </div>

          <div className="inner border">
            <div className="sidebar" />

            <div className="content">
              <div className="drop-cap squares">
                <div className="square" />
                <div className="square" />
                <div className="square" />
              </div>

              {!isEmpty(body) && (
                <div dangerouslySetInnerHTML={{ __html: body }} />
              )}
            </div>
          </div>
        </div>

        {!isEmpty(postElements.ceros_embed) && (
          <div
            className="full-width-embed"
            dangerouslySetInnerHTML={{ __html: postElements.ceros_embed }}
          />
        )}

        <div className="wrapper">
          <div className="inner no-grid">
            <div className="bottom-of-section" />
          </div>
        </div>
      </main>

      {!isEmpty(infiniteScrollContent) &&
        infiniteScrollContent.map(article => article)}

      {loading && <Loading />}

      <div ref={infiniteScrollTrigger} style={{ height: '45px' }} />
    </Fragment>
  );
}

PackageLander.propTypes = {
  post: PropTypes.shape({}).isRequired,
  packageName: PropTypes.string.isRequired
};
