import includes from 'lodash/includes';

const categoryConfig = [
  'most-creative-people',
  'most-innovative-companies',
  'technology',
  'impact',
  'creativity',
  'work-life',
  'co-design',
  // Co.Design categories
  'cities',
  'graphics',
  'interactive',
  'products',
  'spaces',
  'innovation-by-design'
];

export function isCategory(categories, slug) {
  let i = 0;
  while (i < categories.length) {
    if (categories[i].slug === slug) {
      return true;
    }
    i += 1;
  }
  return false;
}

export default function(slug) {
  return includes(categoryConfig, slug);
}
