// import { canUseDOM } from 'exenv';

export function postFiniteLoading(resp = {}) {
  return {
    type: 'POST_FINITE_LOADING',
    postFinite: resp
  };
}

export function postFiniteLoaded(resp = {}) {
  return {
    type: 'POST_FINITE_LOADED',
    postFinite: {
      posts: resp.posts
    }
  };
}

export function postFiniteLoadError(error) {
  return {
    type: 'POST_FINITE_ERROR',
    postFinite: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    }
  };
}
