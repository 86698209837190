/* eslint-disable no-return-assign */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactJWPlayer from 'react-jw-player';
import { get, isEmpty } from 'lodash';

import loadable from '@loadable/component';

const AdContainer = loadable(() =>
  import('../../../../../components/ad/AdContainer')
);

export default function BlackInTech({ post, packageName }) { // eslint-disable-line
  const [body, setBody] = useState(null);
  const [introExpanded, setIntroExpanded] = useState(false);
  const [introScrollBack, setIntroScrollBack] = useState(null);

  useEffect(() => {
    let bodyContent = '';
    get(post, 'content', []).map(group =>
      group.map(content => (bodyContent += content))
    );
    setBody(bodyContent);
  }, []);

  const buildAudioPlayer = entryId => (
    <ReactJWPlayer
      playlist={`https://cdn.jwplayer.com/v2/media/${entryId}`}
      playerId={`black-in-tech-${entryId}`}
      playerScript="https://cdn.jwplayer.com/libraries/RpfqWGkT.js"
      customProps={{
        skin: { name: 'black-in-tech' }
      }}
      onReady={() => {
        const playerInstance = window.jwplayer(`black-in-tech-${entryId}`);

        // Put Time Slider In Button Container
        const playerContainer = playerInstance.getContainer();
        const buttonContainer = playerContainer.querySelector(
          '.jw-button-container'
        );
        const spacer = buttonContainer.querySelector('.jw-spacer');
        const timeSlider = playerContainer.querySelector('.jw-slider-time');
        buttonContainer.replaceChild(timeSlider, spacer);

        // Capture all current button positions
        const jwIconPlayback = buttonContainer.querySelector(
          '.jw-icon-playback'
        );
        const jwTextElapsed = buttonContainer.querySelector('.jw-text-elapsed');
        const jwSliderTime = buttonContainer.querySelector('.jw-slider-time');
        const jwTextDuration = buttonContainer.querySelector(
          '.jw-text-duration'
        );
        const jwIconVolume = buttonContainer.querySelector('.jw-icon-volume');
        const jwVolumeContainer = buttonContainer.querySelector(
          '.jw-horizontal-volume-container'
        );

        // Cut bad buttons
        // buttonContainer.removeChild(playerContainer.querySelector('.jw-nextup-container'));

        // Remove all default button placements
        buttonContainer.removeChild(jwIconPlayback);
        buttonContainer.removeChild(jwTextElapsed);
        buttonContainer.removeChild(jwSliderTime);
        buttonContainer.removeChild(jwTextDuration);
        buttonContainer.removeChild(jwIconVolume);
        buttonContainer.removeChild(jwVolumeContainer);

        // Re-place all elements in proper order
        buttonContainer.appendChild(jwIconPlayback);
        buttonContainer.appendChild(jwTextElapsed);
        buttonContainer.appendChild(jwSliderTime);
        buttonContainer.appendChild(jwTextDuration);
        buttonContainer.appendChild(jwIconVolume);
        buttonContainer.appendChild(jwVolumeContainer);
      }}
    />
  );

  const createDropCap = (text, accentColor) => {
    if (text) {
      const firstWord = text.split(' ')[0];
      const isAlphabet = /^[a-z]+$/i;
      let firstCharIndex = 0;

      for (let i = 3; i < firstWord.length; i += 1) {
        if (firstWord.charAt(i).match(isAlphabet)) {
          firstCharIndex = i;
          break;
        }
      }

      const beforeLetter = firstWord.slice(0, 3);
      const letter = firstWord.slice(3, firstCharIndex + 1);
      const afterLetter = firstWord.slice(firstCharIndex + 1);

      const dropCapClass = `${beforeLetter}<span class='dropCap accent-${accentColor}' >${letter}</span>${afterLetter}`;
      const newText = text.replace(firstWord, dropCapClass);

      return newText;
    }

    return text;
  };

  const addSmallArrow = (text, accentColor = 'red') => {
    if (text) {
      const firstWord = text.split(' ')[0];
      const smallArrow = `<span class='smallArrow  accent-${accentColor}'>></span>`;

      const beforeArrow = firstWord.slice(0, 3);
      const afterArrow = firstWord.slice(3);

      const arrowText = beforeArrow + smallArrow + afterArrow;
      const newText = text.replace(firstWord, arrowText);

      return newText;
    }
    return text;
  };

  const sections = get(post, 'customFields.black_in_tech', []);
  const footer = get(post, 'customFields.black_in_tech_footer', {});

  // Chunking of Intro paragraph text
  const bodyArray = [];
  let m;
  const bodyText = body || '';
  const regex = /<p>.*?<\/p>/gm;

  while ((m = regex.exec(bodyText)) !== null) { // eslint-disable-line
    if (m.index === regex.lastIndex) {
      regex.lastIndex++; // eslint-disable-line
    }

    bodyArray.push(m[0]);
  }

  bodyArray[0] = createDropCap(bodyArray[0], 'red');

  return (
    <main className="content-container black-in-tech">
      <div className="post__article-top-ad-wrapper">
        <AdContainer type="break" />
      </div>

      <h1 className="eyebrow">{get(post, 'title') && get(post, 'title')}</h1>

      <div className="wrapper outer background-black">
        <div className="wrapper inner">
          <div
            className="ceros-embed"
            dangerouslySetInnerHTML={{
              __html: get(post, 'customFields.ceros_embed', '')
            }}
          />
        </div>
      </div>

      <div className="wrapper narrow">
        {!isEmpty(bodyArray) && (
          <Fragment>
            <div className="intro">
              {bodyArray.map((text, index) => {
                if (!introExpanded && index > 3) {
                  return null;
                }
                return <div dangerouslySetInnerHTML={{ __html: text }} />;
              })}
              {!introExpanded && (
                <div
                  className="expander"
                  onClick={() => {
                    setIntroExpanded(true);
                    setIntroScrollBack(window.scrollY);
                  }}
                >
                  + READ MORE
                </div>
              )}
              {introExpanded && (
                <div
                  className="expander"
                  onClick={() => {
                    setIntroExpanded(false);
                    if (introScrollBack)
                      window.scrollTo(window.scrollX, introScrollBack);
                  }}
                >
                  - READ LESS
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>

      {sections.map((section, i) => {
        const [sectionSummaryExpanded, setSectionSummaryExpanded] = useState(
          false
        );
        const [scrollBack, setScrollBack] = useState(null);

        // Chunking of Copy paragraph text
        const sectionCopyArray = [];
        let m2;
        const bodyText2 = section.article_copy || '';
        const regex2 = /<p>.*?<\/p>/gm;

        while ((m2 = regex2.exec(bodyText2)) !== null) { // eslint-disable-line
          if (m2.index === regex2.lastIndex) {
            regex2.lastIndex++; // eslint-disable-line
          }

          sectionCopyArray.push(m2[0]);
        }

        const backgroundColor = section.background_color;
        const textColor =
          section.background_color === 'black' ? 'white' : 'black';
        const accentColor =
          section.accent_color !== '' ? section.accent_color : null;
        const isDropCap = section.article_flourish === 'drop-cap';
        const isSmallArrow = section.article_flourish === 'arrow';

        if (isDropCap) {
          sectionCopyArray[0] = createDropCap(sectionCopyArray[0], accentColor);
        } else if (isSmallArrow) {
          sectionCopyArray[0] = addSmallArrow(sectionCopyArray[0], accentColor);
        }

        const setTemplate = () => {
          switch (section.type) {
            case 'ceros-with-header': {
              let imageCopyClass;
              if (section.image) imageCopyClass = 'imageCopy';

              return (
                <Fragment>
                  {section.hed && <div className="hed">{section.hed}</div>}
                  {section.dek && <div className="dek">{section.dek}</div>}
                  {section.ceros_embed && (
                    <div>
                      {backgroundColor === 'black' ? (
                        <img
                          className="rotateToView"
                          alt="rotateToView"
                          src="https://images.fastcompany.net/image/upload/v1623352414/fcweb/rotatescreen-icon-4darkbg_hfh3ce.svg"
                        />
                      ) : (
                        <img
                          className="rotateToView"
                          alt="rotateToView"
                          src="https://images.fastcompany.net/image/upload/v1623352296/fcweb/rotatescreen-icon-4lightbg_w33lxd.svg"
                        />
                      )}
                      {section.image && (
                        <img
                          loading="lazy"
                          className={`embed ${imageCopyClass}`}
                          src={section.image}
                          alt="2021 Data Shows An Increased Investment In Black Founders"
                        />
                      )}
                      <div
                        className={`embed ${imageCopyClass}`}
                        dangerouslySetInnerHTML={{
                          __html: section.ceros_embed
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              );
            }

            case 'two-column-profile':
              return (
                <Fragment>
                  {section.above_profile_image && (
                    <div class="above-article-image">
                      <img src={section.above_profile_image} alt="" />
                    </div>
                  )}

                  <div className="grid">
                    <div className="col-l">
                      {section.name && (
                        <div className="name">{section.name}</div>
                      )}
                      {section.bio && <div className="bio">{section.bio}</div>}
                      {section.audio_clip_video_id && (
                        <div className="audio">
                          {buildAudioPlayer(section.audio_clip_video_id)}
                        </div>
                      )}
                    </div>

                    <div className="col-r">
                      <div className="copy">
                        {sectionCopyArray.map((text, index) => {
                          if (!sectionSummaryExpanded && index > 0) {
                            return null;
                          }
                          return (
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                          );
                        })}
                        {!sectionSummaryExpanded && (
                          <div
                            className="expander"
                            onClick={() => {
                              setSectionSummaryExpanded(true);
                              setScrollBack(window.scrollY);
                            }}
                          >
                            + READ MORE
                          </div>
                        )}
                        {sectionSummaryExpanded && (
                          <div
                            className="expander"
                            onClick={() => {
                              setSectionSummaryExpanded(false);
                              if (scrollBack)
                                window.scrollTo(window.scrollX, scrollBack);
                            }}
                          >
                            - READ LESS
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              );

            case 'large-quote-with-portrait':
              if (section.name === 'Ken Chenault') {
                return (
                  <div className="grid-container">
                    <div className="col-left-new">
                      {section.name && (
                        <div className="nameBold">{section.name}</div>
                      )}

                      {section.current_role && (
                        <div className="role">
                          <span className="role-label">CURRENT ROLE:</span>
                          <span className="role-content">
                            {section.current_role}
                          </span>
                        </div>
                      )}

                      {section.secondary_role_content && (
                        <div className="role">
                          <span className="role-label">
                            {section.secondary_role_label}:
                          </span>
                          <span className="role-content">
                            {section.secondary_role_content}
                          </span>
                        </div>
                      )}

                      {section.image && (
                        <div className="imageSmall">
                          <img src={section.image} alt="portrait" />
                        </div>
                      )}
                    </div>

                    <div className="col-right-new">
                      {section.quote && (
                        <div className="quote quoteAdditional">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote">
                          {section.quote_footnote}
                        </div>
                      )}
                    </div>

                    <div className="col-audio">
                      {section.quote && (
                        <div className="quote quote-mobile quoteAdditional">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote quote-footnote-mobile">
                          {section.quote_footnote}
                        </div>
                      )}

                      {section.audio_clip_video_id && (
                        <div className="audioAdditional">
                          {buildAudioPlayer(section.audio_clip_video_id)}
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else if (section.variant === 'portrait-right') {
                return (
                  <div className="grid">
                    <div className="col-l">
                      {section.quote && (
                        <div className="quote">{section.quote}</div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote">
                          {section.quote_footnote}
                        </div>
                      )}
                      {section.quote && (
                        <div className="quote quote-mobile">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote quote-footnote-mobile">
                          {section.quote_footnote}
                        </div>
                      )}

                      {section.audio_clip_video_id && (
                        <div className="audio grid-col">
                          {buildAudioPlayer(section.audio_clip_video_id)}
                        </div>
                      )}
                    </div>

                    <div className="col-r">
                      {section.image && (
                        <div className="image">
                          <img src={section.image} alt="portrait" />
                        </div>
                      )}

                      {section.name && (
                        <div className="name portrait-right">
                          {section.name}
                        </div>
                      )}

                      {section.current_role && (
                        <div className="role">
                          <span className="role-label">CURRENT ROLE:</span>
                          <span className="role-content">
                            {section.current_role}
                          </span>
                        </div>
                      )}

                      {section.secondary_role_content && (
                        <div className="role role-secondary">
                          <span className="role-label">
                            {section.secondary_role_label}:
                          </span>
                          <span className="role-content">
                            {section.secondary_role_content}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else if (section.variant === 'portrait-left') {
                return (
                  <div className="grid">
                    <div className="col-l">
                      {section.image && (
                        <div className="image">
                          <img src={section.image} alt="portrait" />
                        </div>
                      )}
                      {section.quote && (
                        <div className="quote quote-mobile">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote quote-footnote-mobile">
                          {section.quote_footnote}
                        </div>
                      )}
                      <div class="audio grid-col">
                        {buildAudioPlayer(section.audio_clip_video_id)}
                      </div>
                    </div>

                    <div className="col-r">
                      {section.name && (
                        <div className="name">{section.name}</div>
                      )}

                      {section.current_role && (
                        <div className="role">
                          <span className="role-label">CURRENT ROLE:</span>
                          <span className="role-content">
                            {section.current_role}
                          </span>
                        </div>
                      )}

                      {section.secondary_role_content && (
                        <div className="role role-secondary">
                          <span className="role-label">
                            {section.secondary_role_label}:
                          </span>
                          <span className="role-content">
                            {section.secondary_role_content}
                          </span>
                        </div>
                      )}

                      {section.quote && (
                        <div className="quote portrait-left">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote">
                          {section.quote_footnote}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              return false;

            case 'big-quotation-mark':
              return (
                <div className="grid">
                  <div className="col-l">
                    {section.name && <div className="name">{section.name}</div>}

                    {section.current_role && (
                      <div className="role">
                        <span className="role-label">CURRENT ROLE:</span>
                        <span className="role-content">
                          {section.current_role}
                        </span>
                      </div>
                    )}

                    {section.secondary_role_content && (
                      <div className="role">
                        <span className="role-label">
                          {section.secondary_role_label}:
                        </span>
                        <span className="role-content">
                          {section.secondary_role_content}
                        </span>
                      </div>
                    )}

                    {section.image && (
                      <div className="image">
                        <img src={section.image} alt="portrait" />
                      </div>
                    )}

                    {section.audio_clip_video_id && (
                      <div className="audio">
                        {buildAudioPlayer(section.audio_clip_video_id)}
                      </div>
                    )}
                  </div>

                  <div className="col-r">
                    {section.quote && (
                      <div className="quote">
                        <img
                          src="https://assets.fastcompany.com/image/upload/v1623252995/fcweb/Path_248.svg"
                          alt=""
                          height="148"
                          width="133"
                        />
                        {section.quote}
                      </div>
                    )}
                  </div>
                </div>
              );

            case 'full-width-profile':
              return (
                <Fragment>
                  {section.image && (
                    <div
                      className="image"
                      style={{ backgroundImage: `url('${section.image}')` }}
                    >
                      <img src={section.image} alt="portrait" />
                    </div>
                  )}
                  {section.mobile_image && (
                    <div
                      className="mobile-image"
                      style={{
                        backgroundImage: `url('${section.mobile_image}')`
                      }}
                    >
                      <img src={section.mobile_image} alt="mobile portrait" />
                    </div>
                  )}

                  {section.name && <div className="name">{section.name}</div>}

                  {section.bio && <div className="bio">{section.bio}</div>}

                  {section.audio_clip_video_id && (
                    <div className="audio">
                      {buildAudioPlayer(section.audio_clip_video_id)}
                    </div>
                  )}

                  {Array.isArray(sectionCopyArray) &&
                    sectionCopyArray.length > 0 && (
                      <div className="wrapper narrow">
                        <div className="copy">
                          {sectionCopyArray.map((text, index) => {
                            if (!sectionSummaryExpanded && index > 0) {
                              return null;
                            }
                            return (
                              <div dangerouslySetInnerHTML={{ __html: text }} />
                            );
                          })}
                          {!sectionSummaryExpanded && (
                            <div
                              className="expander"
                              onClick={() => {
                                setSectionSummaryExpanded(true);
                                setScrollBack(window.scrollY);
                              }}
                            >
                              + READ MORE
                            </div>
                          )}
                          {sectionSummaryExpanded && (
                            <div
                              className="expander"
                              onClick={() => {
                                setSectionSummaryExpanded(false);
                                if (scrollBack)
                                  window.scrollTo(window.scrollX, scrollBack);
                              }}
                            >
                              - READ LESS
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </Fragment>
              );

            case 'full-width-quote':
              return (
                <Fragment>
                  {section.image && (
                    <div className="image">
                      <img src={section.image} alt="portrait" />
                    </div>
                  )}

                  {section.quote && (
                    <div className="quote">{section.quote}</div>
                  )}

                  {section.quote_footnote && (
                    <div className="quote-footnote">
                      {section.quote_footnote}
                    </div>
                  )}

                  <div className="grid">
                    <div className="col-l">
                      {section.quote && (
                        <div className="quote quote-mobile">
                          {section.quote}
                        </div>
                      )}

                      {section.quote_footnote && (
                        <div className="quote-footnote quote-footnote-mobile">
                          {section.quote_footnote}
                        </div>
                      )}

                      {section.audio_clip_video_id && (
                        <div className="audio">
                          {buildAudioPlayer(section.audio_clip_video_id)}
                        </div>
                      )}
                    </div>

                    <div className="col-r">
                      {section.name && (
                        <div className={`name ${section.name.toLowerCase()}`}>
                          {section.name}
                        </div>
                      )}

                      {section.current_role && (
                        <div className="role">
                          <span className="role-label">CURRENT ROLE:</span>
                          <span className="role-content">
                            {section.current_role}
                          </span>
                        </div>
                      )}

                      {section.secondary_role_content && (
                        <div className="role">
                          <span className="role-label">
                            {section.secondary_role_label}:
                          </span>

                          <span className="role-content">
                            {section.secondary_role_content}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              );

            default:
              return false;
          }
        };

        return (
          <Fragment>
            <div
              key={`section-${i}`}
              className={`wrapper outer background-${backgroundColor} text-${textColor}${
                accentColor ? ` accent-${accentColor}` : ''
              }`}
            >
              <div className="wrapper">
                <div
                  className={`segment ${section.type} theme-${get(
                    section,
                    'background_color',
                    'default'
                  )}`}
                >
                  {setTemplate()}
                </div>
              </div>
            </div>
            {i % 3 === 0 && (
              <div className="post__article-top-ad-wrapper mid-package-ad">
                <AdContainer type="break" />
              </div>
            )}
          </Fragment>
        );
      })}

      <div className="wrapper narrow">
        {footer && (
          <div className="black-in-tech-footer">
            {footer.footer_credits && (
              <div
                className="credits"
                dangerouslySetInnerHTML={{ __html: footer.footer_credits }}
              />
            )}
            {footer.footer_copy && (
              <div
                className="copy"
                dangerouslySetInnerHTML={{ __html: footer.footer_copy }}
              />
            )}
            {footer.footer_photo_credits && (
              <div
                className="photo-credits"
                dangerouslySetInnerHTML={{
                  __html: footer.footer_photo_credits
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="post__article-top-ad-wrapper">
        <AdContainer type="break" />
      </div>
    </main>
  );
}

BlackInTech.propTypes = {
  post: PropTypes.shape({}).isRequired,
  packageName: PropTypes.string.isRequired
};
