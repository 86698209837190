/**
 * Determine whether a URL path is associated with a special redesign test article
 * @param {string} URL pathname
 * @returns {boolean}
 */
export default function isRedesignedArticle(postId) {
  const everGreenArticlesList = [
    90393447,
    40523559,
    90334451,
    90687369,
    90664136,
    90824249,
    90643793
  ];

  if (!postId) return false;

  try {
    if (everGreenArticlesList.includes(postId)) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
}
