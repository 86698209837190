import get from 'lodash/get';
import shuffle from 'lodash/shuffle';
import map from 'lodash/map';
import extend from 'lodash/extend';
import authUtil from '../../utils/auth_util';
import request from '../../utils/request_util';
import Base from '../base_model';
import { imageSizesLookup } from '../../utils/cloudinary_asset_util';
import log from '../../services/logger_service';

export default class PostNativeAd extends Base {
  static fetch(config, nativeAdsData) {
    const options = {};
    const host = get(config, 'apiEndpoint') || 'fc-api.fastcompany.com';
    const site = get(config, 'site');
    const protocol = get(config, 'protocol') || 'https://';
    // api has auth in beta
    if (get(config, 'authEnabled')) {
      extend(options, authUtil({ config }));
    }
    /*
      Fetch Logic
      fcAds = {
        clickthrough_url: 'https://www.google.com', // click url,
        article_ids: [40523919], // array of ids to choose from, could have further logic to pick from a list
        campaign_name: 'John Hancock', // hiscox, only for when ids not avail, id supercedes campaign name
        testing_environment: '[%testing_environment%]', // test
        ad_zone: '%%ADUNIT%%', // adops use
        native_type: 'Dianomi Tiles', // inf scroll, dianomi or w.e
        must_reads_position: null, // which slot for dianomi for example
        caption_text: 'Test Caption Text', // if set override title
        sponsor: 'Retirement Stories', // eyebrow
        sort: 'recent', // popular/recent/random
        targeting_map: '%%PATTERN:TARGETINGMAP%%' // adops use
      };

      - When given article ID, stories from those ids, maximum tbd (2?)
      - if campaign name is available (tag) get stories from certain tag
      - if ID AND campaign name is given, IDs take precedence
      - sort: popular/recent/random
    */

    let ids = get(nativeAdsData, 'article_ids');
    const nativeType = get(nativeAdsData, 'native_type');
    const sort = get(nativeAdsData, 'sort');
    const campaign = get(nativeAdsData, 'campaign_name');
    const cardType = get(nativeAdsData, 'card_type');
    const clickthroughURL = get(nativeAdsData, 'clickthrough_url');
    const clickthroughPrefix = get(nativeAdsData, 'clickthrough_prefix');
    const poster = get(nativeAdsData, 'poster');
    const sponsor = get(nativeAdsData, 'sponsor');
    const alwaysOnImage = get(nativeAdsData, 'alwaysOn_image');

    if (!nativeType) {
      return Promise.resolve({
        nativeType
      });
    }

    // sanitize id, dfp returns ids as string
    if (ids && ids[0]) {
      ids = ids[0].trim().split(',');
    }

    // Has a clickthrough URL, signalfies to override the data with ad provided copy
    const isNullID = get(ids, '[0]') === '';

    // ///
    if (alwaysOnImage) {
      // console.log('WE HAVE AN ALWAYS ON IMAGE', nativeType);
      return Promise.resolve({
        nativeType,
        posts: [
          {
            id: '000',
            alwaysOnImage,
            cardType: 'alwaysOn',
            events: false,
            noLazy: false,
            sponsored: true,
            clickthroughURL,
            clickthroughPrefix
          }
        ]
      });
    }

    if (clickthroughURL && isNullID && !campaign) {
      return Promise.resolve({
        nativeType,
        posts: [
          {
            id: '12345',
            categories: [
              {
                name: get(nativeAdsData, 'sponsor')
              }
            ],
            title: get(nativeAdsData, 'caption_text'),
            link: get(nativeAdsData, 'clickthrough_url'),
            featured_image: {
              source: get(nativeAdsData, 'poster')
            },
            currentSite: site,
            assetSizes: imageSizesLookup.bigFirstMobile,
            tags: [{ name: 'advertorial', slug: 'advertorial' }],
            cardType,
            customEyebrowText: sponsor,
            events: false,
            noLazy: true,
            sponsored: true,
            external: true,
            alwaysOnImage
          }
        ]
      });
    }

    // No ID but campaign is provided, then get by campaign/tag
    if (isNullID && campaign) {
      return request
        .getAsync(
          `${protocol}${host}/api/v2/posts-tag-slugs/${site}/${this.cleanParam(
            campaign
          )}?total=10&with_body=1`,
          options
        )
        .then(result => {
          if (!result) {
            log.error(
              `Error requesting api: ${protocol}${host}/api/v2/posts-tag-slugs/${site}/${this.cleanParam(
                campaign
              )}?total=10&with_body=1 from postNativeAdModule.`
            );
          }

          // remove video and short posts
          let filteredPosts = get(result, 'data.posts', []).filter(
            post => post.layout !== 'short' && post.hasVideoPoster === false
          );

          filteredPosts.map(post => {
            const postModified = post;
            postModified.cardType = cardType;
            postModified.assetSizes = imageSizesLookup.bigFirstMobile;
            postModified.link = `${clickthroughPrefix}${postModified.link}`;
            return postModified;
          });

          if (sort === 'random' || sort === 'popular') {
            filteredPosts = shuffle(filteredPosts);
          }

          return {
            nativeType,
            posts: filteredPosts
          };
        });
    }

    // Has ID, then fetch these ids, default order=recent
    // allow only 1 card when it's homepage
    if (nativeType === 'homepageFeaturedTile' && get(ids, 'length') > 1) {
      if (sort === 'random') {
        ids = shuffle(ids);
      }

      ids = ids.slice(0, 1);
    }

    const idFetchPromises = map(ids, id => {
      // REMEMBER TO CHANGE BACK
      const url = `https://fc-api.fastcompany.com/api/v3/post-related/fastcompany/${id}`;
      // const url = `${protocol}${host}/api/v3/post-related/fastcompany/${id}`;
      return request.getAsync(url, options).then(result => {
        if (!result) {
          log.error(`Error requesting api: ${url} from postNativeAdModule.`);
        }
        const postDataModified = get(result, 'data.post', {});
        postDataModified.cardType = cardType;

        // Homepage has one story card, change clickthrough url if exist
        if (
          nativeType === 'homepageFeaturedTile' &&
          get(ids, 'length') === 1 &&
          clickthroughURL
        ) {
          postDataModified.link = clickthroughURL;
          postDataModified.assetSizes = imageSizesLookup.bigFirstMobile;
        }

        // Homepage has one story card, change clickthrough url if exist
        if (
          nativeType === 'homepageFeaturedTile' &&
          get(ids, 'length') === 1 &&
          poster
        ) {
          if (postDataModified && postDataModified.featured_image) {
            postDataModified.assetSizes = imageSizesLookup.bigFirstMobile;
            postDataModified.featured_image = {
              source: poster,
              caption: null,
              width: 1930,
              height: 1080
            };
          }
        }

        // Sometimes the article id given is a short one, in that case change it so components can display img
        if (postDataModified.layout === 'short') {
          postDataModified.layout = 'long';
        }

        // Sometimes the article id given is a short one, in that case change it so components can display img
        if (postDataModified.layout === 'short') {
          postDataModified.layout = 'long';
        }

        postDataModified.link = `${clickthroughPrefix}${postDataModified.link}`;

        return postDataModified;
      });
    });

    return Promise.all(idFetchPromises).then(function fetchedNativeAdData(
      result
    ) {
      return {
        nativeType,
        posts: result
      };
    });
  }
}
