// getVideoCustomParams

export default function(post) {
  const { tags, categories, slug } = post;
  const tagList = [];
  const catList = [];
  // console.log(post);

  const slugList = slug.split('-');

  tags.forEach(tag => {
    tagList.push(tag.slug);
  });

  slugList.forEach(splitSlug => {
    tagList.push(splitSlug);
  });

  categories.forEach(category => {
    catList.push(category.slug);
  });

  return {
    cat: catList.toString(),
    tags: tagList.toString()
  };
}
