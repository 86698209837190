import * as postFiniteActions from '../../action_creators/post/post_finite_action_creators';
import PostFiniteModel from '../../../models/post/post_finite_module';
import log from '../../../services/logger_service';
// import { setAB } from '../../../utils/ab_test_util';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import fetchMic2020Company from '../mic/async_mic2020_company_actions';
import fetchMic2020Region from '../mic/async_mic2020_regions_actions';
import fetchMic2020Sector from '../mic/async_mic2020_sectors_actions';

export default function fetchPostDetail(
  dispatch,
  id,
  advertorialTag,
  config,
  companies,
  regions,
  sectors,
  override
) {
  dispatch(postFiniteActions.postFiniteLoading());
  return PostFiniteModel.fetch(id, advertorialTag, override, config)
    .then(function handlePostDetailData(resp) {
      const store = {
        getState() {
          return { config };
        }
      };

      // const state = store.getState();
      // const abtest = state.config.abtest

      const { data } = resp;
      // Object.keys(data).forEach(key => {
      //   if (Array.isArray(data[key])) {
      //     setAB(data[key],abtest);
      //   }
      // });

      const tags = flatten(data.posts.map(post => post.tags));

      const isMic2020CompanyPost = find(tags, {
        name: 'Most Innovative Companies 2020'
      });

      const isMic2020SectorPost = find(tags, {
        name: 'Most Innovative Companies 2020 Sectors'
      });

      const isMic2020RegionPost = find(tags, {
        name: 'Most Innovative Companies 2020 Regions'
      });

      const promises = [];

      // TODO: we should try to pass in store here but since it's client side render we are ok
      if (isMic2020SectorPost || (isMic2020RegionPost && !regions.length)) {
        promises.push(
          Promise.all([
            fetchMic2020Sector({}, store)(dispatch),
            fetchMic2020Region({}, store)(dispatch)
          ])
        );
      }

      if (isMic2020CompanyPost && !companies.length) {
        promises.push(fetchMic2020Company({}, store)(dispatch));
      }

      return Promise.all(promises).then(() =>
        dispatch(postFiniteActions.postFiniteLoaded(data))
      );
    })
    .catch(function handleUserError(err) {
      dispatch(postFiniteActions.postFiniteLoadError(err));
      log.error(err, `Error in post finite actions, message: ${err.message}`);
    });
}
