/* eslint-disable no-return-assign, no-unused-vars, no-undef, no-shadow, no-restricted-globals */

import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import axios from 'axios';

import loadable from '@loadable/component';

const PackageArticleListEntry = loadable(() =>
  import('./package_article_list_entry')
);
const AdContainer = loadable(() =>
  import('../../../../../components/ad/AdContainer')
);
const Loading = loadable(() =>
  import('../../../../../components/loading_spinner/loading_spinner')
);

export default function PackageArticleList(props) {
  const { post, packageName } = props;

  const postElements = {
    featured_image: get(post, 'featured_image.source', null),
    headline_image: get(
      post,
      'customFields.package_article.styling.image_before_headline',
      null
    ),
    author: !isEmpty(
      get(post, 'customFields.package_article.attribution.custom_byline', null)
    )
      ? get(
          post,
          'customFields.package_article.attribution.custom_byline',
          null
        )
      : get(post, 'author.name', null),
    title: get(post, 'title', null),
    excerpt: get(post, 'excerpt', null),
    ceros_header_override: get(
      post,
      'customFields.package_article.styling.ceros_header_override',
      null
    ),
    drop_cap: {
      type: get(post, 'customFields.package_article.styling.drop_cap', null),
      image: get(
        post,
        'customFields.package_article.styling.drop_cap_image',
        null
      )
    },
    ceros_embed:
      post.customFields.ceros_embed === false
        ? null
        : post.customFields.ceros_embed
  };

  // Refs
  const articleInfiniteScrollTrigger = useRef(null);
  const articleObserver = useRef(null);
  const articleInfiniteScrollIndex = useRef(0);

  // States
  const [articleListBody, setArticleListBody] = useState(null);
  const [
    articleInfiniteScrollContent,
    setArticleInfiniteScrollContent
  ] = useState([]);
  const [articleListIndex, setArticleListIndex] = useState(0);
  const [articleLoading, setArticleLoading] = useState(false);

  // Functions
  function callback([entry]) {
    if (
      entry.isIntersecting &&
      articleInfiniteScrollIndex.current <
        post.customFields.package_landers.infinite_scroll_articles.length
    ) {
      articleObserver.current.unobserve(articleInfiniteScrollTrigger.current);
      setArticleLoading(true);

      axios(
        `//fc-api.fastcompany.com/api/v2/preview-post-related/fastcompany/${
          post.customFields.package_landers.infinite_scroll_articles[
            articleInfiniteScrollIndex.current
          ].article_id
        }`
      ).then(res => {
        setArticleInfiniteScrollContent(articleInfiniteScrollContent => [
          ...articleInfiniteScrollContent,
          <PackageArticleListEntry
            post={res.data.post}
            packageName={packageName}
            scrollIndex={articleInfiniteScrollIndex.current}
            articleCount={articleInfiniteScrollIndex.current}
          />
        ]);

        if (
          articleInfiniteScrollIndex.current <
          post.customFields.package_landers.infinite_scroll_articles.length
        ) {
          setArticleListIndex(articleListIndex => articleListIndex + 1);
        } else {
          articleObserver.disconnect();
        }

        setArticleLoading(false);
      });
    }
  }

  // Effects
  useEffect(() => {
    articleObserver.current = new IntersectionObserver(callback);

    document.body.classList.add('package-article-list'); // Really don't want to do this but gotta hack it in somehow to adjust top padding

    let articleListBodyContent = '';
    post.content.map(group =>
      group.map(content => (articleListBodyContent += content))
    );
    setArticleListBody(articleListBodyContent);
  }, []);

  useEffect(
    () => {
      if (articleObserver.current) {
        const articles = document.querySelectorAll(
          'main.package-article-list-entry'
        );

        articleObserver.current.observe(articleInfiniteScrollTrigger.current);
      }
    },
    [articleInfiniteScrollContent]
  );

  useEffect(
    () => {
      articleInfiniteScrollIndex.current = articleListIndex;
    },
    [articleListIndex]
  );

  return (
    <Fragment>
      <main className={`package-article-list ${packageName}`}>
        <div className="ad-container">
          <AdContainer type="break" />
        </div>

        <div
          className="featured-image"
          style={{ backgroundImage: `url(${postElements.featured_image})` }}
        />

        <div className="wrapper">
          <div className="header">
            {!isEmpty(postElements.headline_image) && (
              <img
                alt=""
                className="headline-image"
                src={postElements.headline_image}
              />
            )}
            {!isEmpty(postElements.title) && <h2>{postElements.title}</h2>}
            {!isEmpty(postElements.excerpt) && <h3>{postElements.excerpt}</h3>}
            {!isEmpty(postElements.author) && (
              <h4>
                By {postElements.author}
                {postElements.photo_credit &&
                  `/Photographs by ${postElements.photo_credit}`}
              </h4>
            )}
          </div>

          {!isEmpty(postElements.ceros_header_override) && (
            <div
              dangerouslySetInnerHTML={{
                __html: postElements.ceros_header_override
              }}
            />
          )}

          <div className="inner border">
            <div className="sidebar" />

            <div className="content">
              {postElements.drop_cap.type &&
                !postElements.drop_cap.type !== 'none' && (
                  <div
                    className={`drop-cap ${
                      postElements.drop_cap.type === 'graphical'
                        ? 'squares'
                        : ''
                    } ${postElements.drop_cap.type === 'image' ? 'image' : ''}`}
                  >
                    {postElements.drop_cap.type === 'graphical' && (
                      <Fragment>
                        <div className="square" />
                        <div className="square" />
                        <div className="square" />
                      </Fragment>
                    )}

                    {postElements.drop_cap.type === 'image' && (
                      <img alt="" src={postElements.drop_cap.image} />
                    )}
                  </div>
                )}

              {!isEmpty(articleListBody) && (
                <div dangerouslySetInnerHTML={{ __html: articleListBody }} />
              )}
            </div>
          </div>
        </div>

        {!isEmpty(postElements.ceros_embed) && (
          <div
            className="full-width-embed"
            dangerouslySetInnerHTML={{ __html: postElements.ceros_embed }}
          />
        )}
      </main>

      {!isEmpty(articleInfiniteScrollContent) &&
        articleInfiniteScrollContent.map(article => article)}

      {articleLoading && <Loading />}

      <div ref={articleInfiniteScrollTrigger} />
    </Fragment>
  );
}

PackageArticleList.propTypes = {
  post: PropTypes.shape({}).isRequired,
  packageName: PropTypes.string.isRequired
};
