// import { canUseDOM } from 'exenv';

export function postNativeAdLoading(resp = {}) {
  return {
    type: 'POST_NATIVE_AD_LOADING',
    postNativeAd: resp
  };
}

export function postNativeAdLoaded(resp = {}) {
  return {
    type: 'POST_NATIVE_AD_LOADED',
    postNativeAd: {
      nativeType: resp.nativeType,
      posts: resp.posts
    }
  };
}

export function postNativeAdLoadError(error) {
  return {
    type: 'POST_NATIVE_AD_ERROR',
    postNativeAd: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    }
  };
}
