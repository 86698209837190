import * as postNativeAdActions from '../../action_creators/post/post_native_ad_action_creators';
import PostNativeAdModel from '../../../models/post/post_native_ad_module';
import log from '../../../services/logger_service';

export default function fetchPostNativeAd(dispatch, config, nativeAdsData) {
  dispatch(postNativeAdActions.postNativeAdLoading());
  return PostNativeAdModel.fetch(config, nativeAdsData)
    .then(function handlePostNativeAdData(resp) {
      dispatch(postNativeAdActions.postNativeAdLoaded(resp));
    })
    .catch(function handlePostNativeAdError(err) {
      dispatch(postNativeAdActions.postNativeAdLoadError(err));
      log.error(err, `Error in post native ad actions ${err.message}`);
    });
}
