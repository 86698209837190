import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import extend from 'lodash/extend';
import remove from 'lodash/remove';
import set from 'lodash/set';
import toNumber from 'lodash/toNumber';
import authUtil from '../../utils/auth_util';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

export default class PostFiniteScrollModel extends Base {
  static fetch(id, advertorialTag, override, config) {
    const cacheEnabled = get(config, 'cacheEnabled');
    const cacheExpire = get(config, 'cacheExpire');
    const cacheNamespace = get(config, 'cacheNamespace');
    const site = get(config, 'site');
    const host = get(config, 'apiEndpoint') || 'fc-api.fastcompany.com';
    const protocol = get(config, 'protocol') || 'https://';
    const options = {};

    // api has auth in beta
    if (get(config, 'authEnabled')) {
      extend(options, authUtil({ config }));
    }

    const advertorialTagQueryString = advertorialTag
      ? `?advertorialTag=${advertorialTag}`
      : '';

    let cacheKeyUrl = `${protocol}${host}/api/v2/${site}/post-finite${advertorialTagQueryString}`;

    if (override === 'recommender') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-category-slug/${site}/recommender?with_body=true`;
    } else if (override === 'mcp') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/most-creative-people-2020?with_body=true`;
    } else if (override === 'mcp2021') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/_package-mcp-2021?with_body=true`;
    } else if (override === 'bwi') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/best-workplaces-for-innovators-2020?with_body=true`;
    } else if (override === 'bwi-2021') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/_package-bwi-2021?with_body=true`;
    } else if (override === 'ibd') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/innovation-by-design-2020?with_body=true`;
    } else if (override === 'mic') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/most-innovative-companies-2021?with_body=true`;
    } else if (override === 'innovation-festival-360') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/innovation-festival-360?with_body=true`;
    } else if (override === 'mcp-2022') {
      cacheKeyUrl = `${protocol}${host}/api/v2/posts-tag-slugs/${site}/most-creative-people-2022?with_body=true`;
    }

    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}`;

    function removeId(resp) {
      const posts = get(resp, 'data.posts');
      remove(posts, { id: toNumber(id) });
      set(resp, 'data.posts', posts);
      return resp;
    }

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options).then(removeId);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(removeId)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
